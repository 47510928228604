import {
    INewsDetail,
    IGetNewsListReq,
    IGetNewsListRes,
    INewsCommodityListReq,
    INewsCommodityListRes,
    IInquiryFormReq,
    IInquiryCaptchaReq,
    IGetNewsDetailReq,
} from '../interface/news';
import {API, IAxiosRequestConfig} from '@/base/http';

const PATHS = {
    search: '/news/search',
    like: '/news/like',
    detail: '/news',
    commodityList: '/news/commodity/list',
    submitInquiry: '/news/leads/create',
    inquiryCaptcha: '/news/leads/captcha',
};

const DEFAULT_PAGE_SIZE = 10;

/**
 * 获取资讯列表
 */
export const getNewsList = (params: IGetNewsListReq) => {
    const {page = 1, page_size = DEFAULT_PAGE_SIZE} = params || {};

    return API.get<IGetNewsListRes>(PATHS.search, {data: {...params, page, page_size}});
};

export const getNewDetail = (params: IGetNewsDetailReq, config?: IAxiosRequestConfig) => {
    return API.get<INewsDetail>(PATHS.detail, {data: params, ...config});
};

export const setNewsLike = (params: {id: number}) => {
    return API.post(PATHS.like, {data: params});
};

/**
 * 列表页
 * @api https://yapi.baidu-int.com/project/17619/interface/api/2774603
 */
export const getNewCommodityList = (params: INewsCommodityListReq) => {
    return API.get<INewsCommodityListRes>(PATHS.commodityList, {data: params});
};

/**
 * 询盘提交
 * @api https://yapi.baidu-int.com/project/17619/interface/api/2875775
 */
export const fetchSubmitInquiry = (params: IInquiryFormReq, config?: IAxiosRequestConfig) => {
    return API.post(PATHS.submitInquiry, {...config, data: params});
};

/**
 * 询盘提交短信验证码
 * @api https://yapi.baidu-int.com/project/17619/interface/api/2877476
 */
export const fetchInquiryCaptcha = (params: IInquiryCaptchaReq) => {
    return API.get(PATHS.inquiryCaptcha, {data: params});
};

import "core-js/modules/es.array.push.js";
import { computed, defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
// import {useHead} from '@vueuse/head';
import Newslist from './components/SubjectNewsList.vue';
import Platform from './components/SubjectPlatform.vue';
import TLayout from '@/components/Layout/Layout.vue';
import UpdateTime from '@/components/UpdateTime/index.vue';
import useStoreRequest from '@/base/hooks/useStoreRequest';
import { getSubjectInfo } from '@/base/api/subject';
import { SubjectMutationEnum } from '@/store/subject';
const tabConf = [{
  label: '资讯列表',
  key: 'search'
}, {
  label: '国家站',
  key: 'plateform'
}];
export default defineComponent({
  name: 'SubjectDetail',
  components: {
    TLayout,
    UpdateTime,
    Newslist,
    Platform
  },
  setup() {
    const route = useRoute();
    const keyword = ref(route.params.keyword || '');
    // useHead({...DEFAULT_SEO_CONFIG, title: (pageTitle || DEFAULT_SEO_CONFIG.title)});
    // start - 主体信息头部
    const {
      state: subjectInfoRes
    } = useStoreRequest({
      space: 'subject',
      mutationName: SubjectMutationEnum.SET_SUBJECT_INFO_DATA,
      stateKey: 'subjectInfo'
    }, getSubjectInfo, {
      word: keyword.value
    });
    const subjectInfoData = computed(() => {
      return subjectInfoRes.value.data;
    });
    // end - 主体信息头部
    // start - 主体详情页tab
    const subjectTab = computed(() => {
      const retArr = [];
      tabConf.forEach(item => {
        var _subjectInfoData$valu;
        if ((_subjectInfoData$valu = subjectInfoData.value) !== null && _subjectInfoData$valu !== void 0 && (_subjectInfoData$valu = _subjectInfoData$valu.tag) !== null && _subjectInfoData$valu !== void 0 && _subjectInfoData$valu.includes(item.key)) {
          retArr.push(item);
        }
      });
      return retArr;
    });
    const activeName = ref('search');
    // end - 主体详情页tab
    return {
      keyword,
      activeName,
      subjectTab,
      subjectInfoData
    };
  }
});
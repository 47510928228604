import { defineComponent, ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import useStoreRequest from '@/base/hooks/useStoreRequest';
import { getSubjectMutiplat } from '@/base/api/subject';
import { SubjectMutationEnum } from '@/store/subject';
// 国家平台的判断逻辑： 先判断前台 和 后台地址，2个都没有的情况下用默认地址
export default defineComponent({
  name: 'SubjectPlatform',
  components: {},
  setup() {
    const route = useRoute();
    const keyword = ref(route.params.keyword || '');
    const {
      state: getSubjectMutiplatRes
    } = useStoreRequest({
      space: 'subject',
      mutationName: SubjectMutationEnum.SET_SUBJECT_MUTIPLAT,
      stateKey: 'subjectMutiplat'
    }, getSubjectMutiplat, {
      word: keyword.value
    });
    const subjectMutiplatData = computed(() => {
      return getSubjectMutiplatRes.value.data;
    });
    return {
      subjectMutiplatData
    };
  }
});
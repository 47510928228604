import { defineComponent, computed } from 'vue';
import { timeStampFormat } from '@/base/utils/time';
import { useStore } from '@/store';
export default defineComponent({
  name: 'UpdateTime',
  setup() {
    const store = useStore();
    const updateTime = `${timeStampFormat(new Date().valueOf(), 'yyyy-MM-dd')}`;
    const isSpider = computed(() => {
      return store.state.spiderInfo.isSpider;
    });
    return {
      updateTime,
      isSpider
    };
  }
});
import {
    IGetSubjectInfo,
    IGetSubjectMutiplat,
    IGetSubjectMergeList,
} from '../interface/subject';
import {API} from '@/base/http';

const PATHS = {
    subjectInfo: '/subject',
    subjectMutiplat: '/subject/mutiplat',
    subjectMergeList: '/subject/combine',
};

export const getSubjectInfo = (params: {word: string}) => {
    return API.get<IGetSubjectInfo>(PATHS.subjectInfo, {data: params});
};

export const getSubjectMutiplat = (params: {word: string}) => {
    return API.get<IGetSubjectMutiplat>(PATHS.subjectMutiplat, {data: params});
};

export const getSubjectMergeList = () => {
    return API.get<IGetSubjectMergeList>(PATHS.subjectMergeList);
};
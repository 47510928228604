import { defineComponent, ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import TNewsSkeleton from '@/views/News/components/NewsSkeleton.vue';
import { handleUrl } from '@/base/utils/url';
import TEmpty from '@/views/News/components/TEmpty/index.vue';
import { scLog } from '@/base/log/sclog';
import useStoreRequest from '@/base/hooks/useStoreRequest';
import { getNewsList } from '@/base/api/news';
import { SubjectMutationEnum } from '@/store/subject';
export default defineComponent({
  name: 'SubjectNewsList',
  components: {
    TEmpty,
    TNewsSkeleton
  },
  setup() {
    const isLoading = false;
    const route = useRoute();
    const keyword = ref(route.params.keyword || '');
    const {
      state: subjectNewsListRes,
      getData
    } = useStoreRequest({
      space: 'subject',
      mutationName: SubjectMutationEnum.SET_SUBJECT_NEWS_LIST_DATA,
      stateKey: 'subjectNewsList'
    }, getNewsList, {
      query: keyword.value
    });
    const subjectNewsList = computed(() => {
      return subjectNewsListRes.value.data;
    });
    const handleChangePageaction = currentPage => {
      if (currentPage % 1 !== 0) {
        currentPage = Math.ceil(currentPage);
      }
      getData({
        query: keyword.value,
        page: currentPage
      });
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    };
    const formatTimestamp = time => {
      if (time) {
        return time.split(' ')[0].replace(/\./g, '-');
      }
      return '';
    };
    const newsClick = (item, index) => {
      scLog('click', {
        elem: 'news-item-click',
        pos: index.toString(),
        articleId: item.id
      });
    };
    return {
      isLoading,
      subjectNewsList,
      handleUrl,
      formatTimestamp,
      newsClick,
      handleChangePageaction
    };
  }
});
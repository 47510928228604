import {computed, onMounted, onServerPrefetch, UnwrapRef} from 'vue';
import {IUseRequestState, useRequest, UseRequestGetDataMethod} from './useRequest';
import {IModuleMutation, IModuleState, useStore} from '@/store';

type StoreNameSpace = keyof IModuleMutation;

type UseRequestArgs = Parameters<typeof useRequest>;

/**
 * 该 hook 是对 useRequest 的二次封装，可以直接将从远程拉取的数据存放到所设置的的全局 Store 中
 * 全局 Store 在设置 state 类型时，该 state 的类型必须与 fetch 方法返回的响应数据类型一致，否则类型推断会出错
 */
const useStoreRequest = <
    StoreSpace extends StoreNameSpace,
    StateKey extends keyof IModuleState[StoreSpace],
    Func extends (...args: any) => any,
    Params extends Parameters<Func>[0],
    Return extends GetPromiseReturnType<ReturnType<Func>>,
    Data extends GetResDataType<Return>
>(
    storeOption: {
        space: StoreSpace;
        mutationName: IModuleMutation[StoreSpace];
        stateKey: StateKey;
        onServerPrefetchCb?: (data: IUseRequestState<Data | undefined>) => any;
        handlePayload?: (data: UnwrapRef<Data> | undefined, state: UnwrapRef<Data> | undefined) => void;
        disabledServerPrefetch?: boolean;
    },
    request: Func,
    params?: UseRequestArgs[1],
    config?: UseRequestArgs[2]
) => {
    const store = useStore();
    const {
        space,
        mutationName,
        stateKey,
        onServerPrefetchCb,
        handlePayload,
        disabledServerPrefetch = false,
    } = storeOption;

    // 拦截 useRequest 参数
    const isFirstTrigger = config?.firstTrigger ?? true;
    config = Object.assign(config || {}, {firstTrigger: false});
    const {state: _state, getData: _getData} = useRequest<Func, Params, Return, Data>(request, params, config);

    // 二次封装 getData
    const getData: UseRequestGetDataMethod<Func, Data> = async (...args) => {
        const getDataReturnVal = await _getData(...args);
        const payload = handlePayload
            ? handlePayload(_state.data, store.state[space][stateKey] as UnwrapRef<Data> | undefined)
            : _state.data;

        store.commit(`${space}/${mutationName}`, payload);
        return getDataReturnVal;
    };

    // 修改 useRequest 的 firstTrigger 逻辑
    if (isFirstTrigger) {
        onServerPrefetch(async () => {
            if (!disabledServerPrefetch) {
                await getData();
                if (typeof onServerPrefetchCb === 'function') {
                    await onServerPrefetchCb(_state);
                }
            }
        });
        onMounted(() => {
            if (!store.state[space][stateKey]) {
                getData();
            }
        });
    }

    const state = computed(() => ({
        ..._state,
        data: store.state[space][stateKey],
    }));

    return {
        state,
        getData,
    };
};

export default useStoreRequest;

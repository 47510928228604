import {reactive, toRefs} from 'vue';
import {IAxiosRequestConfig, IAxiosResponse} from '../http';

export interface IUseRequestState<T extends any> {
    data: T;
    isLoading: boolean;
    hasError: boolean;
    res?: IAxiosResponse<T>;
}

export type UseRequestGetDataMethod<T extends (...args: any) => any, Data> = (
    p?: Parameters<T>[0],
    cfg?: IAxiosRequestConfig
) => Promise<IUseRequestState<Data | undefined>>;

export const useRequest = <
    Func extends (...args: any) => any,
    Params extends Parameters<Func>[0],
    Return extends GetPromiseReturnType<ReturnType<Func>>,
    Data extends GetResDataType<Return>
>(
    request: Func,
    params?: Params,
    config?: {
        initData?: Data;
        axiosConfig?: IAxiosRequestConfig;
        silent?: boolean;
        onSuccess?: (res: IAxiosResponse<Data>) => void;
        onError?: (error: Error) => void;
        firstTrigger?: boolean;
    }
) => {
    const {firstTrigger = true} = config || {};

    const state = reactive<IUseRequestState<Data | undefined>>({
        data: config?.initData,
        isLoading: false,
        hasError: false,
        res: undefined,
    });

    // Params 不能复用、运行时传参需要在运行时去推导
    const getData: UseRequestGetDataMethod<Func, Data> = async (p, cfg) => {
        try {
            const data = typeof p === 'undefined' ? params : p;

            const realCfg = typeof cfg === 'undefined' ? config?.axiosConfig : cfg;

            state.isLoading = true;
            const res = await request(data, realCfg);

            state.data = res.data;
            state.res = res;
            state.isLoading = false;

            if (config?.onSuccess) {
                config.onSuccess(res);
            }
            return res;
        }
        catch (error) {
            state.isLoading = false;
            state.hasError = true;

            if (config?.onError) {
                config.onError(error as any);
            }
        }
        return undefined;
    };

    if (firstTrigger) {
        getData();
    }

    return {
        state,
        refsState: toRefs(state),
        getData,
    };
};

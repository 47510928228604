import "core-js/modules/es.array.push.js";
import { defineComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { removeQueryInVueRouteQuery } from '@/base/utils/url';
export default defineComponent({
  name: 'TEmpty',
  props: {
    propsClass: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    },
    isAbsolute: {
      type: Boolean,
      defautl: false
    },
    // 是否直接更改路由
    isChangePath: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    function backNews() {
      // 路由更新才会重新发送请求: 当在newst本身发生错误时不添加query, 达到更新query的目的
      if (route.query.category === 'newest' || props.isChangePath) {
        router.push({
          path: '/news'
        });
        return;
      }
      const query = {
        ...removeQueryInVueRouteQuery(route.query, 'query'),
        category: 'newest'
      };
      router.push({
        query
      });
    }
    return {
      backNews
    };
  }
});